<template>
    <div class="wine-details">

        <div 
                v-if="(product.eye != undefined) || 
                    (product.nose != undefined) || 
                    (product.mouth != undefined) || 
                    (product.pairing != undefined) || 
                    (product.temperature != undefined) || 
                    (product.sweetness != undefined)" 
                class="product-details-list wine-tasting">
            <h3><img src="@public/img/vigneron-online/wine/icon-tasting.png" :alt="$t('shop-product.wine.tasting.title')" />{{$t('shop-product.wine.tasting.title')}}</h3>
            <ul class="iconlist">
                <li v-if="product.eye != undefined">
                    <span class="label">{{ $t('shop-product.wine.tasting.eye') }}</span>
                    <span class="content" v-html="getLocalizedText(product.eye)"></span>
                </li>
                <li v-if="product.nose != undefined">
                    <span class="label">{{ $t('shop-product.wine.tasting.nose') }}</span>
                    <span class="content" v-html="getLocalizedText(product.nose)"></span>
                </li>
                <li v-if="product.mouth != undefined">
                    <span class="label">{{ $t('shop-product.wine.tasting.mouth') }}</span>
                    <span class="content" v-html="getLocalizedText(product.mouth)"></span>
                </li>
                <li v-if="product.pairing != undefined" >
                    <span class="label">{{ $t('shop-product.wine.tasting.pairing') }} </span>
                    <span class="content" v-html="getLocalizedText(product.pairing, { filters : ['breakLines'] })"></span>
                </li>
                <li v-if="product.temperature != undefined && product.temperature.length == 1">{{ $t('shop-product.wine.tasting.temperature-single', [product.temperature[0]]) }}</li>
                <li v-if="product.temperature != undefined && product.temperature.length == 2">{{ $t('shop-product.wine.tasting.temperature-range', [product.temperature[0], product.temperature[1]]) }}</li>
                <li v-if="product.sweetness != undefined"><WineSweetness :product="product" /></li>
            </ul>
        </div>

        <div v-if="product.keep != undefined" class="product-details-list wine-conservation">
            <h3><img src="@public/img/vigneron-online/wine/icon-conservation.png" :alt="$t('shop-product.wine.conservation.title')" />{{$t('shop-product.wine.conservation.title')}}</h3>
            <ul class="iconlist">
                <li v-if="product.keep != undefined">{{ $t('shop-product.wine.conservation.keep', [product.keep]) }}</li>
            </ul>
        </div>

        <div v-if="product.elaboration != undefined" class="product-details-list wine-elaboration">
            <h3><img src="@public/img/vigneron-online/wine/icon-elaboration.png" :alt="$t('shop-product.wine.elaboration.title')" />{{$t('shop-product.wine.elaboration.title')}}</h3>
            <ul class="iconlist">
                <li v-if="product.elaboration != undefined">{{getLocalizedText(product.elaboration)}}</li>
            </ul>
        </div>

        <div 
                v-if="(product.varieties != undefined) || 
                    (product.terroir != undefined) || 
                    (product.ageOfVines != undefined)" 
                class="product-details-list wine-vineyard">
            <h3><img src="@public/img/vigneron-online/wine/icon-vineyard.png" :alt="$t('shop-product.wine.vineyard.title')" />{{$t('shop-product.wine.vineyard.title')}}</h3>
            <ul class="iconlist">
                <li v-if="product.varieties != undefined">{{ $t('shop-product.wine.vineyard.varieties', [getLocalizedText(product.varieties)]) }}</li>
                <li v-if="product.terroir != undefined">{{ $t('shop-product.wine.vineyard.terroir', [getLocalizedText(product.terroir)]) }}</li>
                <li v-if="product.ageOfVines != undefined">{{ $t('shop-product.wine.vineyard.ageOfVines', [product.ageOfVines]) }}</li>
                <li v-if="product.year != undefined">{{ $t('shop-product.wine.vineyard.year', [product.year]) }}</li>
            </ul>
        </div>

    </div>

</template>

<style>

.product-details-list > h3 {
    margin:0 0 10px 0;
    text-transform: uppercase;
    font-size:18px;
}

.product-details-list > h3 > img {
    height:25px;
    margin-right:5px;
}

.product-details-list > ul {
    margin-left: 30px;
}

.product-details-list li {
    margin: 0 0 5px 0;
}

.product-details-list li span.label {
    flex-shrink: 0;
}

.product-details-list li span.content {
    margin-left:5px;
}

@media (max-width: 479px) {
     .product-details-list li {
         flex-wrap: wrap;
     }

     .product-details-list li span.content {
        margin-left:20px;
        flex-basis: 100%;
    }
 }

.wine-tasting .sweetness {
    margin-top:15px;
}

.product-details .si-share span {
    float:left;
    margin-right: 10px;
}

.product-details .si-share div {
    float:left;
}

.product-details .si-share .social-icon {
    border-radius: 3px;
    margin: 0 5px 0 0
}

</style>

<script lang="ts">
import { Component, Prop } from '@fwk-node-modules/vue-property-decorator';
import Vue from '@fwk-node-modules/vue';
import WineSweetness from './WineSweetness.vue';

@Component({
    components : {
        WineSweetness
    }
})
export default class WineDetails extends Vue {
    
    @Prop({
        type: Object,
        required: true
    }) readonly product: any

}
</script>
<template>
    
    <div class="sweetness">
        <div class="legend">
            <span>{{ $t('shop-product.sweetness.dry')}}</span>
            <span>
                <span>{{ $t('shop-product.sweetness.medium-dry')}}</span>
            </span>
            <span>
                <span>{{ $t('shop-product.sweetness.medium-sweet')}}</span>
            </span>
            <span>
                <span>{{ $t('shop-product.sweetness.sweet')}}</span>
            </span>
        </div>
        <div class="sweet-scale">
            <i></i>
            <i></i>
            <i></i>
            <i></i>
        </div>
        <div class="bar">
            <i ref="sweetness"></i>
        </div>
    </div>
            

</template>

<style>

.sweetness .sweet-scale {
    position:relative;
    height:12px;
}

.sweetness .sweet-scale > i {
    width:2px;
    height: 12px;
    background:#555;
    position:absolute;
}

.sweetness .legend {
    position:relative;
    height:22px;
}

.sweetness .legend > span {
    position:absolute;
    text-transform: uppercase;
}

.sweetness .legend > span > span {
    position:relative;
    left:-50%;
}

.sweetness {
    position:relative;
    width: 450px;
    height: 80px;
}

.sweetness .legend > span:nth-child(1),
.sweetness .sweet-scale > i:nth-child(1) {
    left:0px;
}

.sweetness .legend > span:nth-child(2),
.sweetness .sweet-scale > i:nth-child(2) {
    left:149px;
}

.sweetness .legend > span:nth-child(3),
.sweetness .sweet-scale > i:nth-child(3) {
    left:296px;
}

.sweetness .legend > span:nth-child(4),
.sweetness .sweet-scale > i:nth-child(4) {
    left:450px;
}

.sweetness .bar {
    width:100%;
    height:2px;
    background:#555;
    position:relative;
}

.sweetness .bar > i {
    height:15px;
    width:15px;
    border-radius: 15px;
    top:-7px;
    background: var(--secondary-color);
    position:absolute;
    left: calc(var(--wine-sweetness) - 7px);
}

.sweetness .bar > i:after { 
   content:"";
   display:block;
   width:30px;
   height:30px;
   background:url('@public/img/vigneron-online/wine-glasses-icon.png') no-repeat;
   background-size:30px 30px;
   position:relative;
   top: 20px;
   left:-5px;
 }


/* Vertical sweetness */
@media (max-width: 991.98px) {

    .sweetness {
        height: 200px
    }

    .sweetness .legend {
        margin-top: 10px;
        height: 190px;
    }

    .sweetness .legend > span {
        left: 100px!important;
    }

    .sweetness .legend > span:nth-child(2),
    .sweetness .sweet-scale > i:nth-child(2) {
        top: 50px;
    }

    .sweetness .legend > span:nth-child(3),
    .sweetness .sweet-scale > i:nth-child(3) {
        top: 100px;
    }

    .sweetness .legend > span:nth-child(4),
    .sweetness .sweet-scale > i:nth-child(4) {
        top: 150px;
        right: auto;
    }

    .sweetness .legend > span > span {
        left:0px
    }

    .sweetness .sweet-scale {
        height: 200px;
        position: absolute;
        top: 20px;
        left: 0px;
    }

    .sweetness .sweet-scale > i {
        width: 20px;
        height: 2px;
        left: 50px!important;
    }

    .sweetness .bar {
        height: 150px;
        width: 2px;
        position: absolute;
        top: 20px;
        left: 50px;
    }

    .sweetness .bar > i {
        left:-7px;
        top: calc(var(--wine-sweetness) - 7px);
    }

    .sweetness .bar > i:after { 
        top: -5px;
        left:-35px;
    }

}
 

</style>

<script lang="ts">
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import Vue from '@fwk-node-modules/vue';

@Component({})
export default class WineSweetness extends Vue {
    
    @Prop({
        type: Object,
        required: true
    }) readonly product: any

    mounted() {
        if(this.product.sweetness != undefined) {
            //@ts-ignore
            this.$refs.sweetness.style.setProperty('--wine-sweetness', this.product.sweetness+'%');
        }
        else {
            //@ts-ignore
            this.$refs.sweetness.style.display = 'none';
        }
        
    }

}
</script>
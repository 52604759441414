<template>
    <div class="row add-to-cart" ref="addToCart">
        <div v-if="detailsInCart == undefined" class="col-xl-12">
            <ActivityCalendar :product="product"></ActivityCalendar>
        </div>
        <div v-else class="col-xl-12">
            <div class="activity-in-cart card">
                <div class="card-body">
                    <div class="card-text">
                        {{ formatDate(detailsInCart.startDate) }}<br/>
                        {{ formatDateTime(detailsInCart.startDate) }} - {{ formatDateTime(addMinutes(new Date(detailsInCart.startDate), product.duration)) }}<br/>
                        {{ $cms.utils.getComputedLabel(labels.numberOfPersons, {params:[detailsInCart.numberOfPersons]}) }}<br/>
                        {{ $cms.utils.getComputedLabel(labels.price, {params:[formatPriceAmount(detailsInCart.amount)]}) }}
                        <div :class="inCartCssClass">
                            <i class="icon-check"></i>
                            {{ $t("shop.product-in-cart") }}
                            <button class="button button-dark button-rounded ladda-button product-remove" data-style="zoom-in" @click="onRemoveClick" :title="$t('shop.product.remove-button_title')"><i class="icon-trash2 m-0"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>

</style>

<script lang="ts">
import { defineComponent, PropType, computed, onMounted,nextTick,  toRefs, onBeforeUnmount, ref, Ref, reactive, watch } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { languagesTypes } from '@fwk-client/store/types';
import { types as cartTypes } from '@fwk-client/modules/shop/stores/cart';
import { formatDay, FormatDateType, formatTime, FromLocalToUTC, formatDateForInput, FromUTCToLocal } from '@igotweb-node-api-utils/formatter';
import { addMinutes } from '@igotweb-node-api-utils/date';
import { CmsContent, CmsEnum, CmsLabel, CmsPicture, CmsText, CmsFile, CmsNumber } from '@fwk-client/modules/cms/types';
import ActivityCalendar from './ActivityCalendar.vue';
import * as Ladda from 'ladda';

/** @cmsLabels */
export interface AddToCartLabels {
    /** @cmsType CmsLabel */
    numberOfPersons?:CmsLabel
    /** @cmsType CmsLabel */
    price?:CmsLabel
}

export default defineComponent({
    props: {
        product: Object as PropType<any>,
        labels: {
          type: Object as PropType<AddToCartLabels>,
          default: () => { return {} }
        },
    },
    components : {
        ActivityCalendar
    },
    setup(props, context) {
        const app = getApp();
        const $router = useRouter();
        const $store = useStore();

        const computedLabels:AddToCartLabels = {
            numberOfPersons : {
                fr : "Nombre de personnes: {0}",
                de : "Anzahl der Personen: {0}",
                en : "Number of persons: {0}"
            },
            price : {
                fr : "Prix: {0}",
                de : "Preis: {0}",
                en : "Price: {0}"
            },
            ...props.labels
        }

        const { product } = toRefs(props);

        const addToCart:Ref<HTMLElement|null> = ref(null);

        const detailsInCart = computed(() => {
            return $store.getters['shop/cart/' + cartTypes.getters.GET_CART_PRODUCT_DETAILS](product.value);
        })

        const currentLanguageCode = $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];

        if(detailsInCart.value) {
            nextTick(() => {
                if(addToCart.value) {
                    enableRemoveButton()
                }
            })
        }

        if(detailsInCart.value && detailsInCart.value.numberOfPersons != undefined) {
            // form.numberOfPersons = detailsInCart.value.numberOfPersons;
        }
        if(detailsInCart.value && detailsInCart.value.startDate != undefined) {
            // form.startDate = detailsInCart.value.startDate;
        }

        /* Shared with ProductList */
        const inCartCssClass = computed(() => {
            var cssClass = "product-in-cart mt-2"
            if(detailsInCart.value && detailsInCart.value.numberOfPersons > 0) {
                return cssClass
            }
            return cssClass + " d-none"
        })

        var laddaRemove:Ladda.LaddaButton|null = null;

        const enableRemoveButton = () => {
            // @ts-ignore
            var removeButton:HTMLButtonElement|null = addToCart.value.querySelector( '.product-in-cart button.ladda-button' );
            laddaRemove = Ladda.create(removeButton!);
        }

        const onRemoveClick = (evt:Event) => {
            evt.preventDefault();

            laddaRemove!.start();
            app.$shop.cart.removeActivity(product.value).then((response:any) => {
                laddaRemove!.stop();
            });
        }

        const formatDate = (date:Date) => {
            return formatDay(date, currentLanguageCode, {type: FormatDateType.LONG, isUTC:true});
        }

        const formatDateTime = (date:Date) => {
            return formatTime(date, currentLanguageCode, {isUTC:true});
        }

        /*
        watch(
            () => form,
            (val:any, oldVal:any) => {
                if(detailsInCart.value && 
                    (
                        (detailsInCart.value.numberOfPersons > 0 && val.numberOfPersons != detailsInCart.value.numberOfPersons) || 
                        (detailsInCart.value.startDate != undefined && val.startDate.getTime() != detailsInCart.value.startDate.getTime())
                    )
                ) {
                    // We need to update the cart
                    isActivityInCartLoading.value = true;
                    app.$shop.cart.updateActivity(product.value, val.numberOfPersons, val.startDate).then((response:any) => {
                        isActivityInCartLoading.value = false;
                    });
                }
            },
            { deep: true }
        )

        */

        watch(
            detailsInCart,
            (val:any, oldVal:any) => {
                if(val !== undefined) {
                    nextTick(() => {
                        enableRemoveButton()
                    })
                }
            }

        )

        return {
            product,
            inCartCssClass,
            onRemoveClick,
            detailsInCart,
            addToCart,
            formatDate,
            formatDateTime,
            addMinutes,
            labels: computedLabels
        }

    }
})
</script>
<template>
  <section class="shop-product content">
    
    <div class="content-wrap">

      <div class="container clearfix">

        <p class="back-to-shop">
          <router-link :to="{ name : (product && product.type) == 'ACTIVITY' ? 'shop-activities' : 'shop-products', params : { lang : currentLanguageCode, shopPath : $shopContent_path }}">
            <i class="icon-angle-left1"></i>
            {{ $t("shop.back-to-shop") }}
          </router-link>
        </p>

        <div v-if="!product">
          <h1>{{ $t('shop-product.no-product') }}</h1>
        </div>
        <div v-else>
      
          <div class="product-title">
            <h1 >{{product.title}} <span v-if="product.year" class="year nocolor">({{product.year}})</span></h1>
            <h2 v-if="product.subtitle && product.subtitle != ''"> - {{product.subtitle}}</h2>
          </div>

          <ProductDetails :product="product" :key="product._id"></ProductDetails>   

          <RelatedProducts v-if="relatedProducts.length > 0" :products="relatedProducts" class="mb-5"></RelatedProducts>   

        </div>       

        <p class="back-to-shop">
          <router-link :to="{ name : (product && product.type) == 'ACTIVITY' ? 'shop-activities' : 'shop-products', params : { lang : currentLanguageCode, shopPath : $shopContent_path }}">
            <i class="icon-angle-left1"></i>
            {{ $t("shop.back-to-shop") }}
          </router-link>
        </p>

			</div>

    </div>

    <StickyCart></StickyCart>

  </section><!-- #content end -->

</template>

<style>

/* Specific position for back to shop link */
.shop-product p.back-to-shop:last-child {
  margin-left:0px;
}

/* Specific position for back to shop link in case of square logo */
@media (max-width: 575.98px) {
  .layout:has(#header.square-logo) .shop-product p.back-to-shop {
    margin-left:120px;    
  }
}

/* Specific position for back to shop link in case of square logo */
@media (min-width: 576px) {
  .layout:has(#header.square-logo) .shop-product p.back-to-shop {
    margin-left:140px;    
  }
}

/* Specific position for back to shop link in case of square logo */
@media (min-width: 1200px) {
  .layout:has(#header.square-logo) .shop-product p.back-to-shop {
    margin-left:100px;    
  }
}



/* We add top margin between the content and the back to shop link */
.shop-product .container > div {
  margin-top: 20px;
}

.shop-product .container > div > .product-title {
  position:relative;
  margin-bottom:30px;
  padding: 0 0 10px;
  border-bottom: 2px solid var(--secondary-color);
  display:flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

/* We remove the margin of product title */
.shop-product .container > div > .product-title h1 {
  margin:0px;
}

@media (max-width: 767.98px) {
  .shop-product .container > div > .product-title h2 {
    font-size: 20px;
  }
}

@media (max-width: 399.98px) {
  .shop-product .container > div > .product-title h1 {
    font-size: 20px;
  }

  .shop-product .container > div > .product-title h2 {
    font-size: 16px;
  }
}


</style>


<script lang="ts">
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import { Component, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import ProductDetails from '../../panels/shop/product/ProductDetails.vue';
import RelatedProducts from '../../panels/shop/product/RelatedProducts.vue';
import StickyCart from '../../cms/canvas/shop/StickyCart.vue'
import ProductMixin from '../../mixins/Product.vue';

@Component({
  components: {
    ProductDetails, 
    RelatedProducts,
    StickyCart
  }
})
export default class Product extends mixins(GenericPage, ProductMixin) {

  product:any = null;

  destroyed() {}

  created() {
    if(this.$route.params && this.$route.params.productCode) {
        this.showProductFromCode(this.$route.params.productCode);
    }
  }

  get productMode() {
    return (this.$route.meta && this.$route.meta.shop) ? this.$route.meta.shop.productMode : undefined;
  }

  showProductFromCode(code:string) {
    // We need to get the associated product from code
    var product = this.productMode == 'ACTIVITY' ? this.$shop_activityFromCode(code) : this.$shop_productFromCode(code);
    if(product) {
        this.product = product;
        // We send analytics event
        this.$shop.product.viewProductDetails(product);
    }
  }

  mounted() {
    
  }	

  get relatedProducts() {
    return this.$shop.product.getRelatedProducts(this.product);
  }

  @Watch('$route.params', { deep: true })
  onRouteParamsChange(value: any, oldVal: any) {
    if(value.productCode && value.productCode != this.product.code) {
      this.showProductFromCode(value.productCode);
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }
  
}
</script>
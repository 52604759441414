<template>
    <div class="product-details single-product" ref="product">
        <div class="product">
            <div class="row col-mb-30 m-0">
                <div class="col-md-5">

                    <div class="row product-image">

                        <div class="col-xl">
                            <!-- Product Single - Gallery
                            ============================================= -->
                            <img :src="currentPictureURL" :alt="getProductImageAlt(product)" />
                        </div>

                        <div class="col-xl-auto product-images">
                            <div v-if="productPictures.length > 1" v-swiper="swiperOption" class="swiper" ref="swiperThumbs">
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide" v-for="(url, index) in getThumbnailsURLs(product)" :key="'picture'+index">
                                        <a href="javascript:void(0)" @click="onThumnailClick(index)">
                                            <img :src="url" alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    
                    
                    

                </div>

                <div class="col-md-7 product-desc">

                    <!-- Product Single - Price & Capacity
                    ============================================= -->
                    <div class="product-price" v-if="product.type !== 'ACTIVITY'"><del v-if="product.price && product.price.nonDiscountedAmount != product.price.amount">{{ formatPriceNonDiscountedAmount(product.price) }}</del> <ins>{{ formatPriceAmount(product.price) }}</ins></div>
                    <div class="product-price" v-if="product.type == 'ACTIVITY'"><del v-if="product.pricePerPerson && product.pricePerPerson.nonDiscountedAmount != product.pricePerPerson.amount">{{ formatPriceNonDiscountedAmount(product.pricePerPerson) }}</del> <ins>{{ formatPriceAmount(product.pricePerPerson) }}</ins> {{ $t("shop.activities.price-per-person") }}</div>
                    <div v-if="product.capacity" class="product-capacity"><span>-</span><span>{{product.capacity}}cl</span></div>
                    <div v-if="product.degree" class="product-degree"><span>-</span><span>{{product.degree}} % vol</span></div>
                    <div v-if="product.duration" class="product-duration"><span>-</span><span>{{product.duration}}min</span></div>
                    <!-- Product Single - Price & Capacity End -->
                    <div class="flags" v-if="product.isBio">
                        <img src="@public/img/vigneron-online/wine/icon-bio.jpeg" alt="BIO" />
                    </div>

                    <!-- Product Single - Rating
                    ============================================= -->
                    <div v-if="product.rating" class="product-rating">
                        <i class="icon-star3"></i>
                        <i class="icon-star3"></i>
                        <i class="icon-star3"></i>
                        <i class="icon-star-half-full"></i>
                        <i class="icon-star-empty"></i>
                    </div><!-- Product Single - Rating End -->

                    <div class="clear"></div>
                    <div class="line"></div>

                    <!-- Product Single - Quantity & Cart Button
                    ============================================= -->
                    <ProductAddToCart v-if="product.type !== 'ACTIVITY'" :product="product" ></ProductAddToCart>
                    <div v-if="product.type !== 'ACTIVITY'" class="clear"></div>
                    <div v-if="product.type !== 'ACTIVITY'" class="line"></div>

                    <!-- Product Single - Short Description
                    ============================================= -->
                    <LocalizedContent v-if="product.description" tag="h2" :localizedContent="product.description"></LocalizedContent>
                    <ul v-if="product.options" class="iconlist">
                        <li><i class="icon-caret-right"></i> Dynamic Color Options</li>
                        <li><i class="icon-caret-right"></i> Lots of Size Options</li>
                        <li><i class="icon-caret-right"></i> 30-Day Return Policy</li>
                    </ul><!-- Product Single - Short Description End -->

                    <!-- Wine specific information -->
                    <WineDetails :product="product" />

                    <!-- Product Single - Activity Calendar & Cart Button
                    ============================================= -->
                    <ActivityAddToCart v-if="product.type == 'ACTIVITY'" :product="product" ></ActivityAddToCart>
                    <div v-if="product.type == 'ACTIVITY'" class="clear"></div>
                    <div v-if="product.type == 'ACTIVITY'" class="line"></div>


                    <!-- Product Single - Meta
                    ============================================= -->
                    <div class="card product-meta" v-if="false">
                        <div class="card-body">
                            <span itemprop="productID" class="sku_wrapper">SKU: <span class="sku">8465415</span></span>
                            <span class="posted_in">Category: <a href="#" rel="tag">Dress</a>.</span>
                            <span class="tagged_as">Tags: <a href="#" rel="tag">Pink</a>, <a href="#" rel="tag">Short</a>, <a href="#" rel="tag">Dress</a>, <a href="#" rel="tag">Printed</a>.</span>
                        </div>
                    </div><!-- Product Single - Meta End -->

                    <!-- Product Single - Share
                    ============================================= -->
                    <div class="si-share border-0 clearfix">
                        <span>{{$t('shop-product.share')}}</span>
                        <ShareLinks :title="product.title"></ShareLinks>
                    </div><!-- Product Single - Share End -->

                </div>

            </div>

        </div>

    </div>

</template>

<style>

/* We set the size of the picture */
.product-details .product-image {
    height:auto;
    width: 95%;
}

@media (max-width: 767.98px) {
    /* We ensure that picture is taking the full width when description wrap */
    .product-details .product-image {
        margin:0;
        width: 100%;
    }   
}

/* We set the size of the picture */
.product-details .product-image img {
    width: auto;
    margin-left:auto;
    margin-right:auto;
    max-height: 800px; 
}

/* We ensure that thumbnails fit in the space */
.product-details .product-image .product-images img {
    max-height: 100%;
}


/* We set specific size for capacity and degree */
.single-product .product-capacity,
.single-product .product-degree {
  font-size: 24px;
}

.single-product .flags {
    position:absolute;
    top:-15px;
    right:0px;
}

.single-product .flags img {
    height:80px;
}

.product-details h2 {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-family: inherit;
}

.product-images {
    margin-top:5px;
}

.product-image > div {
    padding:0px;
}

.product-images .swiper-slide {
    height: 80px!important;
    width: 80px;
    padding-top: 0px;
    padding-bottom: 0px;
    flex: 0 0 auto;
    text-align: center;
    margin-right: 5px;
}

.product-images img {
    width: auto;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    max-height: 100%;
}

@media (min-width: 1200px) {
    /* We make sure that thumbnails are vertical */
    .product-images .swiper-wrapper {
        flex-direction: column
    }
    /* We make sure that the thumbnails are on the left */
    .product-images {
        order: -1;
        margin-top:0px;
        margin-right:10px;
        padding-left:0px;
    }

    .product-images .swiper-slide {
        margin-right: 0px;
        margin-bottom:5px;
    }
}

@media (min-width: 1400px) {
    .product-images .swiper-slide {
        height: 100px!important;
        width: 100px;
    }
}

</style>

<script lang="ts">
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import ProductAddToCart from './AddToCart.vue';
import ActivityAddToCart from '../activity/AddToCart.vue';
import WineDetails from './WineDetails.vue';
import ShareLinks from '../../input/ShareLinks.vue';
import Product from '../../../mixins/Product.vue';
import LocalizedContent from '@fwk-client/components/panels/LocalizedContent.vue';

// Import Swiper Vue.js components
import { SwiperOptions} from 'swiper';

@Component({
    components : {
        ProductAddToCart,
        ActivityAddToCart,
        WineDetails,
        ShareLinks,
        LocalizedContent
    }
})
export default class ProductDetails extends mixins(Product) {

    @Prop({
        type: Object,
        required: true
    }) readonly product: any

    currentPictureURL = "";

    swiperOption:SwiperOptions = {
        simulateTouch: false,
        // spaceBetween: 10,
        // centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.1,
        // slideToClickedSlide: true
    }

    get productPictures() {
        return this.getPicturesURLs(this.product);
    }

    onThumnailClick(index:number) {
        // We need to display the right picture
        this.currentPictureURL = this.productPictures[index];
    }

    created() {
        this.currentPictureURL = this.productPictures[0]
    }

    @Watch('product')
    onProductChange(value: any, oldVal: any) {
        this.currentPictureURL = this.productPictures[0]
    }

}
</script>
<template>
    <div class="related-products">
        <div class="line mt-0 mb-2"></div>
        <div class="w-100">

            <h4>{{$t('shop-product.related-products')}}</h4>

            <section class="slider-element" ref="slider">
                <div v-swiper="swiperOption" class="swiper slider-inner">

                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="(product, index) in products" :key="'product'+index+'_'+product._id">
                            <div class="product">
                                <div class="product-image">
                                    <router-link :to="getViewDetailsPath(product)">
                                        <img :src="getThumbnailsURLs(product)[0]" :alt="getProductImageAlt(product)" />
                                    </router-link>
                                </div>
                                <div class="product-desc center">
                                    <div class="product-title">
                                        <h3>
                                            <router-link :to="getViewDetailsPath(product)">
                                                {{product.title}} <span v-if="product.year" class="year nocolor">({{product.year}})</span>
                                            </router-link>
                                        </h3>
                                        <h4 v-if="product.subtitle && product.subtitle != ''">{{product.subtitle}}</h4>
                                    </div>
                                    <div class="price-and-capacity">
                                        <div class="product-price" v-if="product.type !== 'ACTIVITY'"><del v-if="product.price && product.price.nonDiscountedAmount != product.price.amount">{{ formatPriceNonDiscountedAmount(product.price) }}</del> <ins>{{ formatPriceAmount(product.price) }}</ins></div>
                                        <div class="product-price" v-if="product.type == 'ACTIVITY'"><del v-if="product.pricePerPerson && product.pricePerPerson.nonDiscountedAmount != product.pricePerPerson.amount">{{ formatPriceNonDiscountedAmount(product.pricePerPerson) }}</del> <ins>{{ formatPriceAmount(product.pricePerPerson) }}</ins> {{ $t("shop.activities.price-per-person") }}</div>
                                        <div v-if="product.capacity" class="product-capacity"><span>-</span><span>{{product.capacity}}cl</span></div>
                                        <div v-if="product.duration" class="product-duration"><span>-</span><span>{{product.duration}}min</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="slider-arrow-left"><i class="icon-angle-left"></i></div>
                    <div class="slider-arrow-right"><i class="icon-angle-right"></i></div>
                </div>

            </section><!-- #slider end -->

        </div>

    </div>

</template>

<style>

.related-products .product {
    display:flex;
    flex-direction: column;
}

.related-products .product .product-image {
    height: 250px!important;
    width: 200px;
    padding-top: 5px;
    padding-bottom: 5px;
    flex: 0 0 auto;
    margin-left:auto;
    margin-right:auto;
}

.related-products .product .product-image > a {
    height: 100%;
}

.related-products .product .product-image img {
    width: auto;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.related-products .swiper-container {
    padding-left:52px;
    padding-right:52px;
}

.related-products .swiper-slide {
    width: auto;
    display:flex;
    height:auto;
}

.related-products .product .product-desc {
    display:flex;
    flex:1 0 auto;
    flex-direction: column;
}

.related-products .product .price-and-capacity {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex: 1 0 auto;
}

.related-products .product .price-and-capacity .product-price {
    margin:0px;
}

</style>

<script lang="ts">
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import Product from '../../../mixins/Product.vue';

// Import Swiper Vue.js components
import { SwiperOptions} from 'swiper';
import { getSwiperOptionsOn } from '@root/src/client/theme/canvas-664/canvas.slider';

@Component({
    components : {
        
    }
})
export default class RelatedProducts extends mixins(Product) {
    
    @Prop({
        type: Array,
        required: true
    }) readonly products!: any[]

    swiperOption:SwiperOptions = {
        simulateTouch: false,
        slidesPerView : 'auto',
        spaceBetween : 50,
        navigation: {
            nextEl: '.slider-arrow-right',
            prevEl: '.slider-arrow-left',
        },
        speed: 500
    }

}
</script>
import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

interface WeekdaysInput {
}

export function useWeekdays(props:WeekdaysInput, {emit}:any) { 
  const app = getApp();
  const store = useStore();

  const weekDays = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday"
  ]
  
  const weekDayLabels = {
    monday : {
        fr : "Lundi",
        en : "Monday",
        de : "Montag"
    },
    tuesday : {
        fr : "Mardi",
        en : "Tuesday",
        de : "Dienstag"
    },
    wednesday : {
        fr : "Mercredi",
        en : "Wednesday",
        de : "Mittwoch"
    },
    thursday : {
        fr : "Jeudi",
        en : "Thursday",
        de : "Donnerstag"
    },
    friday : {
        fr : "Vendredi",
        en : "Friday",
        de : "Freitag"
    },
    saturday : {
        fr : "Samedi",
        en : "Saturday",
        de : "Samstag"
    },
    sunday : {
        fr : "Dimanche",
        en : "Sunday",
        de : "Sonntag"
    }
  }

  return {
    weekDays,
    weekDayLabels
  }
  
}
<template>
    <div class="activity-calendar" ref="activityCalendar" v-if="isInitialCalendarLoaded">

        <div class="row" v-if="periodAvailabilities.length > 0">

            <div class="col-auto">

                <div v-for="(availability, availabilityIndex) in visibleAvailabilities" :key="availabilityIndex + '-availabilityPeriod'" class="period">
                    <div v-if="new Date(availability.startDate).getTime() != new Date(availability.endDate).getTime()">
                        <div class="fw-bold mb-1">{{ $cms.utils.getComputedLabel(labels.schedules) }}</div>
                        
                        <ul class="">
                            <li v-for="(weekDay, weekDayIndex) in Object.keys(availability.slotsPerWeekday)" :key="weekDayIndex + '-weekDay-'+ availabilityIndex" class="d-flex justify-content-between">
                                
                                <div class="">{{ $cms.utils.getComputedLabel(weekDayLabels[weekDay]) }}</div>
                                <ul class="iconlist ms-3">
                                    <li v-for="(daySlot, daySlotIndex) in availability.slotsPerWeekday[weekDay]" :key="daySlotIndex + '-daySlot-'+ availabilityIndex">
                                        <i class="icon-caret-right"></i>
                                        {{ getTimeForSlot(daySlot.startTime) }} - {{ getTimeForSlot(daySlot.endTime) }}
                                    </li>
                                </ul>
                                
                            </li>
                        </ul>
                    </div>
                </div>


            </div>
            

        </div>

        <div class="row">
            <div class="col-12">
                <h3 class="font-body ls1">
                    {{ $cms.utils.getComputedLabel(labels.orderTitle) }}
                </h3>
            </div>
        </div>

        <div class="row">

            <div class="col-12 no-availability" v-if="visibleAvailabilities.length == 0 || periodAvailabilities.length == 0">
                {{ $cms.utils.getComputedLabel(labels.noAvailability) }}
            </div>

            <div class="col-lg-6">
                <FullCalendar ref="fullCalendar" :options="calendarOptions"></FullCalendar>
            </div>

            <div class="col-lg-6">
                <div class="selection">
                    <div v-if="listTimes.length == 0" class="center">{{ $cms.utils.getComputedLabel(labels.selectDate) }}</div>
                    <div v-if="listTimes.length > 0" class="center">{{ $cms.utils.getComputedLabel(labels.selectTime) }}</div>
                    <div v-if="listTimes.length > 0" class="day-slots" ref="daySlotsElements">
                        <button v-for="(daySlot, daySlotIndex) in listTimes" :key="daySlotIndex + '-daySlot'" :data-slot="getAttributeForSlot(daySlot)" class="button button-border button-rounded" type="button" @click="selectTime(daySlot)">{{ getTimeForSlot(daySlot.startTime) }} - {{ getTimeForSlot(daySlot.endTime) }}</button>
                    </div>
                </div>

                <div class="persons" v-if="selectionForm.date != null && selectionForm.startTime != null" ref="personsElements">
                    <div class="center">{{ $cms.utils.getComputedLabel(labels.selectNbOfPersons) }}</div>
                    <div>
                        <Quantity v-bind:value.sync="selectionForm.numberOfPersons" :minValue="1" :isLoading="isActivityInCartLoading"></Quantity>
                    </div>
                    <button :disabled="selectionForm.numberOfPersons == 0 || selectionForm.date == null || selectionForm.startTime == null" :class="buttonCssClass" data-style="zoom-in" type="button" @click="onAddToCartClick">
                        <i class="icon-cart"></i>
                        {{ $t("shop.add-to-cart") }}
                    </button>
                </div>
            </div>

        </div>
        
    </div>

</template>

<style>

:root {
    --fc-today-bg-color: rgba(243, 209, 209, 0.25);
    --fc-highlight-color: rgba(55, 255, 0, 0.522);
    /* --fc-highlight-color: var(--secondary-color) */
}

.activity-calendar .no-availability {
    margin-top:-20px;
    margin-bottom:20px;
    color:orange;
}

.activity-calendar .fc .fc-multimonth {
    border: 0px;
}

.activity-calendar .fc h2.fc-toolbar-title {
    font-size: 1.3em;
    text-transform: capitalize;
}

.activity-calendar .fc .fc-multimonth-header-table,
.activity-calendar .fc .fc-multimonth-daygrid-table  {
    border-left:1px solid var(--fc-border-color);
    border-right:1px solid var(--fc-border-color);
}

.activity-calendar .fc .fc-multimonth-daygrid-table  {
    border-bottom: 1px solid var(--fc-border-color) !important;
}

.activity-calendar .fc-multimonth-title {
    display:none;
}

.activity-calendar .fc .fc-day.selected a.fc-daygrid-day-number,
.activity-calendar .fc .fc-day.selected a.fc-daygrid-day-number:hover {
    color: #FFFFFF
}

/* We ensure days from other months are still visible */
.activity-calendar .fc-day.selected .fc-daygrid-day-top {
    opacity: 1;
}

.activity-calendar .fc a.fc-daygrid-day-number:hover {
    color:var(--themecolor);
}

/* .availability-calendar .fc-day.selected .fc-daygrid-bg-harness, */
.activity-calendar .fc-day.selected .fc-daygrid-bg-harness .fc-event {
    background: var(--secondary-color) !important; 
    opacity: 1;
}
.activity-calendar .fc .fc-highlight {
    background: transparent !important; 
}

.activity-calendar .day-slots .button.button-border:hover {
    background-color: var(--themecolor);
    color: #FFFFFF;
}

.activity-calendar .day-slots {
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
}

.activity-calendar .day-slots .button.selected {
    border-color: transparent !important;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
    background-color: var(--themecolor);
    color: #FFFFFF;
}

.activity-calendar .persons .quantity {
    width:100%;
    margin-left:auto;
    margin-right:auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.activity-calendar .persons button.product-add {
    display: block;
    margin-left:auto;
    margin-right:auto;
}

</style>

<script lang="ts">
import { defineComponent, PropType, computed, onBeforeMount, onBeforeUpdate, nextTick, toRefs,  onMounted, Ref, ref, watch, reactive } from '@fwk-node-modules/vue'
import { languagesTypes } from '@fwk-client/store/types';
import { AvailabilitiesOptions } from '@fwk-client/modules/hospitality/helpers/apartment';
import FullCalendar from '@fullcalendar/vue'
import multiMonthPlugin from '@fullcalendar/multimonth'
import interactionPlugin from '@fullcalendar/interaction'; 
import frLocale from '@fullcalendar/core/locales/fr';
import enLocale from '@fullcalendar/core/locales/en-gb';
import deLocale from '@fullcalendar/core/locales/de';
import { formatDay, FormatDateType, FromLocalToUTC, formatDateForInput, FromUTCToLocal } from '@igotweb-node-api-utils/formatter';
import { startMonth, endMonth, addDays, removeDays, startDay, endDay } from '@igotweb-node-api-utils/date';

import Quantity from '../../input/Quantity.vue';
import { CmsContent, CmsEnum, CmsLabel, CmsPicture, CmsText, CmsFile, CmsNumber } from '@fwk-client/modules/cms/types';
import { types as cartTypes } from '@fwk-client/modules/shop/stores/cart';
import * as Ladda from 'ladda';

import { getApp, useCmsModule, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { useWeekdays } from '@fwk-client/composables/useWeekdays';

/** @cmsLabels */
export interface ActivityCalendarLabels {
    /** @cmsType CmsLabel */
    orderTitle?:CmsLabel
    /** @cmsType CmsLabel */
    schedules?:CmsLabel
    /** @cmsType CmsLabel */
    selectDate?:CmsLabel
    /** @cmsType CmsLabel */
    selectTime?:CmsLabel
    /** @cmsType CmsLabel */
    selectNbOfPersons?:CmsLabel
    /** @cmsType CmsLabel */
    nbOfPersonsNote?:CmsLabel
    /** @cmsType CmsLabel */
    noAvailability?:CmsLabel
}

export default defineComponent({
    props: {
        product: Object as PropType<any>,
        labels: {
          type: Object as PropType<ActivityCalendarLabels>,
          default: () => { return {} }
        },
    },
    components : {
        FullCalendar,
        Quantity
    },
    setup(props, context) {

        const app = getApp();
        const router = useRouter();
        const store = useStore();
        const cms = useCmsModule();

        const { weekDayLabels } = useWeekdays(props, context);

        const computedLabels:ActivityCalendarLabels = {
            orderTitle : {
                fr : "Réservez votre visite",
                en : "Book your visit",
                de : "Buchen Sie Ihren Besuch"
            },
            schedules : {
                fr : "Horaires",
                en : "Schedules",
                de : "Öffnungszeiten"
            },
            selectDate : {
                fr : "Sélectionnez une date",
                en : "Select a date",
                de : "Wählen Sie ein Datum"
            },
            selectTime : {
                fr : "Sélectionnez un horaire",
                en : "Select a time",
                de : "Wählen Sie eine Zeit"
            },
            selectNbOfPersons : {
                fr : "Sélectionnez le nombre de personnes",
                en : "Select the number of persons",
                de : "Wählen Sie die Anzahl der Personen"
            },
            noAvailability : {
                fr: "Il n'y a pas de disponibilité ce mois.",
                en: "There is no availability this month",
                de: ""
            },
            ...props.labels
        }

        const { product } = toRefs(props);

        const isInitialCalendarLoaded:Ref<boolean> = ref(false);
        const isCalendarLoading:Ref<boolean> = ref(false);
        const availabilities:Ref<any> = ref(null)
        const periodAvailabilities:Ref<any[]> = ref([])
        const fullCalendar:Ref<HTMLElement|null> = ref(null);
        const daySlotsElements:Ref<HTMLElement|null> = ref(null);
        const personsElements:Ref<HTMLElement|null> = ref(null);

        const availabilityCalendar:Ref<HTMLElement|null> = ref(null);

        const selectionForm:any = reactive({
            date:null,
            startTime:null,
            numberOfPersons: 1
        })


        onMounted(() => {
            // By default we load the calendar for the current and next three months
            updateCalendar(props.product).then(() => {
                isInitialCalendarLoaded.value = true;
            });
        })

        const updateCalendar = async (product:any, availOptions?:AvailabilitiesOptions) => {
            isCalendarLoading.value = true;
            var avails:any = await app.$shop.activity.getAvailabilities(product, availOptions);
            isCalendarLoading.value = false;
            availabilities.value = {
                ...availabilities.value,
                ...avails.calendar
            };
            // For each availability we check if not already in the list
            for(var periodAvailability of avails.periodAvailabilities) {
                var existingPeriodAvailability = periodAvailabilities.value.find((existingPeriodAvailability:any) => {
                    return existingPeriodAvailability._id == periodAvailability._id;
                })
                if(!existingPeriodAvailability) {
                    periodAvailabilities.value.push(periodAvailability);
                }
            }
        }

        const currentLanguageCode = store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];

        const calendarLocale = computed(() => {
            var calendarSpecificLocales:any = {
                "en" : "en-gb"
            }
            if(calendarSpecificLocales[currentLanguageCode]) {
                return calendarSpecificLocales[currentLanguageCode]
            }
            return currentLanguageCode;
        })

        interface SectionInfo {
            start:Date
            end:Date
        }

        const onDateClick = (info:any) => {
            // Nothing to do
            if(process.env.CONSOLE == "LOG") { 
                console.log("ACTIVITY CALENDAR - ON DATE CLICK");
                console.log(info); 
            }
        }

        /**
         * checkSelectAllow
         * Triggered when the user tries to select a cell or a range.
         */
        const checkSelectAllow = (info:any) => {
            var isAllowed = checkIfSelectionAllowed(info.start);
            if(process.env.CONSOLE == "LOG") { 
                console.log("ACTIVITY CALENDAR - CHECK SELECT ALLOW");
                console.log(info); 
                console.log(isAllowed);
            }
            return isAllowed;
        }

        const getAvailabilityFromDate = (date:Date) => {
            // We get the price associated to selection
            var monthKey:string = date.getFullYear() + '-' + (date.getMonth() + 1);
            var dayIndex = date.getDate() - 1;

            // If there is no calendar day information we cannot select
            if(typeof availabilities.value[monthKey] == 'undefined' ||
                    typeof availabilities.value[monthKey][dayIndex] == 'undefined') {
                return undefined;
            }

            return availabilities.value[monthKey][dayIndex];
        }

        /**
         * checkIfSelectionAllowed
         * startDate
         * This method check that there are slots available for the day
         */
        const checkIfSelectionAllowed = (startDate:Date) => {
            var availability = getAvailabilityFromDate(startDate);
            if(!availability || !availability.daySlots || availability.daySlots.length == 0) {
                return false;
            }

            return true;
        }

        const selectCalendarCell = (startDate:Date) => {
            
            // We unselect other cells
            var selectedCells = ((fullCalendar.value as any).calendar.el as HTMLElement).querySelectorAll(".fc-day.selected");
            selectedCells.forEach((cell:Element) => {
                // We check if the selected cell is not in the range to be selected
                let dateCell = cell.getAttribute("data-date");
                let dateForCell = new Date(dateCell!);
                if(dateForCell.getTime() < startDate.getTime() || dateForCell.getTime() > startDate!.getTime()) {
                    cell.classList.remove("selected");
                }
            })

            var dateForCell = new Date(startDate); 
            var dateAttibute = formatDateForInput(dateForCell, true);
            // We may have several cells for the dates when same day is visible in two month calendars
            var dateCells = (fullCalendar.value as any).calendar.el.querySelectorAll(".fc-day[data-date='"+dateAttibute+"']");
            if(dateCells && dateCells.length > 0) {
                for(var dateCell of dateCells) {
                    if(!dateCell.classList.contains("selected")) {
                        // We add attribute only when date is visible
                        dateCell.classList.add("selected")
                    }
                }
            }
        }

        const computeSectionInfo = (info:any) => {
            // We check if selection is possible
            var startDate = FromLocalToUTC(info.start);
            var endDate = FromLocalToUTC(info.end);

            // We check if the current selection is a range or not.
            var isRangeSelected = true;
            if(endDate.getTime() - startDate.getTime() == 24*60*60*1000) {
                isRangeSelected = false;
            }
            return {
                startDate: startDate,
                endDate: removeDays(new Date(endDate), 1),
                isRangeSelected: isRangeSelected
            }
        }

        const onDatesSelected = (info:SectionInfo) => {

            const { startDate, endDate, isRangeSelected } = computeSectionInfo(info);

            if(process.env.CONSOLE == "LOG") { 
                console.log("ACTIVITY CALENDAR - ON DATES SELECTED");
                console.log(info); 
            }
            
            // We update the form
            if(isRangeSelected) {
                selectionForm.date = new Date(endDate);
                selectCalendarCell(new Date(endDate));
            }
            else {
                selectionForm.date = new Date(startDate);
                selectCalendarCell(new Date(startDate));
            }
        }

        const onDatesUnselected = (info:any) => {

            if(process.env.CONSOLE == "LOG") { 
                console.log("ACTIVITY CALENDAR - ON DATES UNSELECTED");
                console.log(info); 
            }
            // The user is doing a new selection
            // info.jsEvent is null when navigating between months
            if(info.jsEvent && selectionForm.date) {
                // We unselect the date
                var dateForCell = new Date(selectionForm.date); 
                var dateAttibute = formatDateForInput(dateForCell, true);
                // We may have several cells for the dates when same day is visible in two month calendars
                var dateCells = (fullCalendar.value as any).calendar.el.querySelectorAll(".fc-day.selected[data-date='"+dateAttibute+"']");
                if(dateCells && dateCells.length > 0) {
                    for(var dateCell of dateCells) {
                        dateCell.classList.remove("selected");
                    }
                }
                // We remove current selected date
                selectionForm.date = null;
                selectionForm.startTime = null;
            }
        }

        const dayCellClassNames = (info:any) => {
            // console.log(info)
        }

        const numberOfMonths = 1;
        const currentStartDate:Ref<Date> = ref(startMonth(new Date()))
        var endDate = new Date();
        endDate.setMonth(endDate.getMonth() + numberOfMonths);
        const currentEndDate:Ref<Date> = ref(endMonth(endDate))

        const calendarOptions:Ref<any> = ref({
            plugins: [interactionPlugin, multiMonthPlugin],
            locales: [frLocale, enLocale, deLocale],
            locale: calendarLocale,
            initialView: 'multiMonthThreeMonth',
            views: {
                multiMonthThreeMonth: {
                    type: 'multiMonth',
                    duration: { months: numberOfMonths }
                }
            },
            dateIncrement: {
                months: 1
            },
            validRange: function(nowDate:Date) {
                return {
                    start: new Date(nowDate.getFullYear(), nowDate.getMonth(), 1)
                };
            },
            events: [],
            selectable: true,
            unselectAuto: false, // We prevent the unselect when click outside of the calendar
            // aspectRatio: 3,
            selectLongPressDelay: 0,
            height: "auto",
            weekends: true,
            select: onDatesSelected,
            unselect: onDatesUnselected,
            dateClick : onDateClick,
            selectAllow : checkSelectAllow,
            dayCellClassNames : dayCellClassNames,
            customButtons: {
                prev: {
                    click: async function() {
                        let calendarApi = (fullCalendar as any).value.getApi();
                        var startDate = FromLocalToUTC(calendarApi.view.activeStart);
                        startDate.setMonth(startDate.getMonth() - 1);
                        // We add one week to be sure we are on the right month
                        addDays(startDate, 7);
                        startDate = startMonth(startDate)
                        var endDate = endMonth(startDate)

                        // We need to check if we have availablities for this month already or not.
                        // We only check if we have availability for the first and last days of the months
                        // as we always retrieve full months
                        var startAvailability = getAvailabilityFromDate(startDate);
                        var endAvailability = getAvailabilityFromDate(endDate);
                        if(!startAvailability || !endAvailability) {
                            // We get availabilities from backend
                            await updateCalendar(props.product, {
                                startDate: startDate,
                                endDate: endDate
                            })
                        }

                        // We trigger the navigation
                        calendarApi.prev();

                        currentStartDate.value = FromLocalToUTC(calendarApi.view.activeStart);
                        currentEndDate.value = FromLocalToUTC(calendarApi.view.activeEnd);

                        // We reselect the date if selected
                        selectCalendarCell(selectionForm.date);
                    }
                },
                next: {
                    click: async function() {
                        let calendarApi = (fullCalendar as any).value.getApi();
                        var startDate = FromLocalToUTC(calendarApi.view.activeStart);
                        startDate.setMonth(startDate.getMonth() + 3);
                        addDays(startDate, 7);
                        startDate = startMonth(startDate)
                        var endDate = endMonth(startDate)

                        // We need to check if we have availablities for this month already or not.
                        // We only check if we have availability for the first and last days of the months
                        // as we always retrieve full months
                        var startAvailability = getAvailabilityFromDate(startDate);
                        var endAvailability = getAvailabilityFromDate(endDate);
                        if(!startAvailability || !endAvailability) {
                            // We get availabilities from backend
                            await updateCalendar(props.product, {
                                startDate: startDate,
                                endDate: endDate
                            })
                        }

                        calendarApi.next();

                        currentStartDate.value = FromLocalToUTC(calendarApi.view.activeStart);
                        currentEndDate.value = FromLocalToUTC(calendarApi.view.activeEnd);

                        // We reselect the date if selected
                        selectCalendarCell(selectionForm.date);
                    }
                }
            }
        })

        const getTimeForSlot = (slotTime:number) => {
            let hours = Math.trunc(slotTime);
            let hoursStr = hours.toString();
            if(hours < 10) {
                hoursStr = '0' + hours;
            }
            let minutes = (slotTime - hours) * 60;
            let minutesStr = minutes.toString();
            if(minutes < 10) {
                minutesStr = '0' + minutes;
            }
            return hoursStr + ':' + minutesStr;
        }

        const getAttributeForSlot = (daySlot:any) => {
            let hours = Math.trunc(daySlot.startTime);
            let minutes = (daySlot.startTime - hours) * 60;
            return hours + "-" + minutes;
        }

        const selectTime = (daySlot:any) => {
            selectionForm.startTime = daySlot.startTime;

            // We unselect other times
            daySlotsElements.value!.querySelectorAll(".button.selected").forEach((button:Element) => {
                button.classList.remove("selected");
            });

            nextTick(() => {
                enableAddToCartButton();
            });

            // We select the time
            var slotsButtons = daySlotsElements.value!.querySelectorAll(".button[data-slot='"+getAttributeForSlot(daySlot)+"']");
            console.log(slotsButtons);
            if(slotsButtons && slotsButtons.length > 0) {
                for(var slotsButton of slotsButtons) {
                    if(!slotsButton.classList.contains("selected")) {
                        // We add attribute only when date is visible
                        slotsButton.classList.add("selected")
                    }
                }
            }
        }


        const visibleAvailabilities = computed(() => {
            var startDate = currentStartDate.value;
            var endDate = currentEndDate.value;
            return periodAvailabilities.value.filter((availability:any) => {
                return new Date(availability.startDate).getTime() <= endDate.getTime() && new Date(availability.endDate).getTime() >= startDate.getTime();
            }).sort((a:any, b:any) => {
                return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
            })
        })

        const listTimes = computed(() => {
            if(selectionForm.date) {
                var availability = getAvailabilityFromDate(selectionForm.date);
                if(availability && availability.daySlots) {
                    return availability.daySlots;
                }
            }
            return [];
        })

         const detailsInCart = computed(() => {
            return store.getters['shop/cart/' + cartTypes.getters.GET_CART_PRODUCT_DETAILS](product.value);
        })

        /* Shared with ProductList */
        const buttonCssClass = computed(() => {
            var cssClass = "button button-3d ladda-button product-add";
            if(detailsInCart.value && detailsInCart.value.numberOfPersons > 0) {
                return cssClass + " d-none"
            }
            return cssClass
        })

        var laddaSubmit:Ladda.LaddaButton|null = null;
        const isActivityInCartLoading:Ref<boolean> = ref(false);

        const enableAddToCartButton = () => {
            // @ts-ignore   
            var addToCartButton:HTMLButtonElement|null = personsElements.value.querySelector( 'button.ladda-button' );
            laddaSubmit = Ladda.create(addToCartButton!);
        }

        const onAddToCartClick = (evt:Event) => {
            evt.preventDefault();

            laddaSubmit!.start();
            isActivityInCartLoading.value = true;
            var startDate = new Date(selectionForm.date);
            startDate.setUTCHours(Math.trunc(selectionForm.startTime), (selectionForm.startTime - Math.trunc(selectionForm.startTime)) * 60, 0, 0);
            app.$shop.cart.addActivity(product.value, selectionForm.numberOfPersons, startDate).then((response:any) => {
                if(response.added) {
                    
                }
                isActivityInCartLoading.value = false;
                laddaSubmit!.stop();
            });

        }

        watch(
            availabilities,
            (val:any, oldVal:any) => {
                if(availabilities.value) {
                    var events = [];
                    for(var monthKey of Object.keys(availabilities.value)) {
                        var monthCalendar = availabilities.value[monthKey];
                        events.push(...monthCalendar.map((availability:any) => {
                            var date = new Date(availability.date);
                            var backgroundColor = (availability.daySlots && availability.daySlots.length > 0) ? "#ccffcc" : "#ccc";
                            return { 
                                date: date,
                                allDay: true,
                                backgroundColor : backgroundColor,
                                display: "background"
                            }
                        }))
                    }
                    calendarOptions.value.events = events;
                }
                else {
                    calendarOptions.value.events = [];
                }
            },
            { deep: true }
        )
        
        const formatDate = (date:Date) => {
            return formatDay(date, currentLanguageCode, {type: FormatDateType.LONG, isUTC:true});
        }

        // let calendarApi = this.$refs.fullCalendar.getApi()
        // calendarApi.next()

        return {
            isInitialCalendarLoaded,
            weekDayLabels,
            availabilityCalendar,
            isCalendarLoading,
            availabilities,
            calendarOptions,
            fullCalendar,
            selectionForm,
            formatDate,
            periodAvailabilities,
            visibleAvailabilities,
            listTimes,
            getTimeForSlot,
            selectTime,
            daySlotsElements,
            getAttributeForSlot,
            labels: computedLabels,
            buttonCssClass,
            onAddToCartClick,
            isActivityInCartLoading,
            personsElements
        }

    }
})      
</script>